<template>
  <div class="horizontal-menu row static" :class="{vertical:isVertical}">
    <div class="horizontal-menu-item no-flex static"
         @mouseenter="enter(item)" @mouseleave="leave"
         :class="{underline:item.underline, 'no-padding':noPadding, active:item.active, hover:item.hover, 'hidden-smd':hideOnMobile && index<items.length-1}"
         v-for="(item,index) in items">
      <nuxt-link class="hbb row g-4" :class="{active:item.active}" style="display: flex;" @click="click($event,item)"
                 :to="item.link">
        <img width="15px" height="15px" style="width: 15px; height: 15px;" :src="item.image"
             v-if="item.image"/>
        <span style="text-wrap: nowrap;">{{ item.title }}</span>
      </nuxt-link>

      <div v-if="item?.children?.length && maskActive" class="hidden-search-hints-items g-8 row"
           :style="{opacity:!containerActive ? '0!important' : ''}">
        <nuxt-link :to="'/'+sub_item.slug" v-for="sub_item in item.children" class="hidden-search-hint-item g-8">
          <div class="image">
            <img loading="lazy" :width="56" :height="56" :src="sub_item.image" :alt="sub_item.title"/>
          </div>
          <p>{{ sub_item.name }}</p>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import Image from "~/components/atoms/Image.vue";

export default {
  components: {Image},
  props: {
    items: Array,
    maskActive: {Boolean, default: false},
    noPadding: {Boolean, default: false},
    hideOnMobile: {Boolean, default: true},
    isVertical: {Boolean, default: false}
  },
  data() {
    return {
      containerActive: false,
      timer: null
    }
  },
  methods: {
    click(e, item) {
      if (item.callback) {
        e.stopPropagation()
        e.preventDefault()

        item.callback(item)
      }
    },
    enter(item) {
      if (!item.children?.length) return
      clearTimeout(this.timer)
      this.containerActive = true
      this.$emit('openHiddenContainer')
    },
    leave() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.containerActive = false
        this.$emit('closeHiddenContainer')
      }, 200)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixins.scss";

.horizontal-menu {
  overflow-x: auto;
  overflow-y: visible;
  max-width: 100%;
  padding-bottom: 5px;
}

.horizontal-menu-item {
  font-size: 13px;
  color: $black;
  text-transform: uppercase;
  letter-spacing: .07em;
  display: inline-block;
  border-bottom: 0 solid;
  padding: 0 16px;

  &:first-child {
    padding-left: 0;
  }

  &:not(.active):after {
    @include smd {
      display: none;
    }
  }

  @include smd {
    font-size: 12px;
  }


  &.no-padding {
    padding: 0;
  }

  &.active, &:hover {
    font-weight: 500;
    cursor: pointer;

    a span {
      font-weight: 500;
    }
  }

  &.underline {
    a {
      text-decoration: underline;
    }
  }

  .hidden-search-hints-items {
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1002;
    transition-property: visible, opacity;
    transition-duration: 0s, .2s;
    transition-timing-function: ease-in-out;
    transition-delay: .1s, .2s;
    top: 30px;
    padding-top: 65px;
    height: 100px;

    @include smd {
      display: none;
    }

    .hidden-search-hint-item {
      min-width: 133px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .image {
        min-width: 56px;
        min-height: 56px;
      }

      p {
        color: $black;
        letter-spacing: .56px;
        text-transform: capitalize;
        font-weight: 400;
      }

      &:hover {
        p {
          font-weight: 600;
        }
      }
    }
  }

  &:hover {
    .hidden-search-hints-items {
      visibility: visible;
      opacity: 1;
    }
  }
}

.horizontal-menu {
  &.vertical {
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;

    .horizontal-menu-item {
      padding: 0;
    }
  }
}

</style>
